import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './containers/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';
import { TokenInterceptor } from './token.interceptor';
import { AuthComponent } from './auth.component';
import { AuthRoutingModule } from './auth-routing.module';
import { ToastModule } from 'primeng/toast';
import { NbInputModule, NbIconModule, NbFormFieldModule } from '@nebular/theme';

@NgModule({
  declarations: [LoginComponent, AuthComponent],
  providers: [
    AuthGuard,
    AuthService,
    // RandomGuard,
    /* {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    } */
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    ToastModule,
    NbInputModule,
    NbIconModule,
    NbFormFieldModule,
  ]
})
export class AuthModule { }
