import {Directive, HostListener} from '@angular/core';
import {NavigationServiceService} from '../../@core/services/navigation-service.service';

@Directive({
  selector: '[appBackNavigation]'
})
export class BackNavigationDirective {

  constructor(private navigationService: NavigationServiceService) { }

  @HostListener('click')
  onClick(): void {
    this.navigationService.back();
  }

}
