import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { userSignIn } from 'src/app/@core/models/mcd';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { BackgroundService } from '../../services/background.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  loading: boolean;
  loadingText: string;
  disabled: boolean;

  // Avant le
  click = false;
  message = 'connexion';
  promesse;
  userSignIn: userSignIn;



  error = {
    message: '',
    isError: false,
    class: 'error-css'
  };

  translations: any = null;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    private service: MessageService,
    private backgroundService: BackgroundService,
  ) {}

  async ngOnInit() {
    this.backgroundService.animateBackground('mainParent', 0);
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
    // this.checkIfConnected();
  }

  get f() {
    return this.loginForm.controls;
  }

  login() {
    this.click = true;
    this.message = 'Connexion en cours...';
    this.userSignIn = {
      username: this.f.email.value.toString(),
      email: this.f.email.value.toString(),
      password: this.f.password.value.toString(),
      client_secret: environment.client_secret,
      client_id: environment.client_id,
      grant_type :"password",
      scope :  "*"
    }
    // alert('Hi');
    this.authService.login(this.userSignIn, (response) => {
        this.message = 'Connexion';
        this.click = false;
        if (response) {
          this.service.add({severity: 'info', detail: ' BIENVENUE SUR LIGHTOIL'});
        } else {
          this.service.add({severity: 'warn', detail: 'ECHEC D\'AUTHENTIFICATION'});
        }
    }, (error) => {
        this.click = false;
        this.message = 'Connexion';
        console.log(error);
        this.service.add({severity: 'warn', detail: 'UNE ERREURE EST SURVENUE LORS DE LA CONNEXION'});
    });
  }

}
