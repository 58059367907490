export const environment = {
  production: true,
  apiUrl : "https://www.lightoil.cm:8886/api/",
  oauth: "https://www.lightoil.cm:8886/oauth/token",
  client_id: "9d0c0bd4-4ea6-4876-b747-f35654aaeac4",
  client_secret: "APH3To8GnAzWT8JTxl29ZdsM1th5NIOxUVpgC1ih",
  redirect_uri_path: "https://www.lightoil.cm:8886/api",
  admin_view: "https://www.lightoil.cm:8996/",
  light_oil:  "https://www.lightoil.cm:6996/",
  super_admin: "http://www.lightoil.cm:6003/"
};
