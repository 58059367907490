// import { Injectable } from '@angular/core';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { BehaviorSubject, Observable, throwError } from 'rxjs';
// import { catchError, tap } from 'rxjs/operators';
// import { environment } from 'src/environments/environment';
// import { CookieService } from 'ngx-cookie-service';
// import { User } from '../../models/user.model';

// import { userSignIn } from '../../models/mcd';
// import { Role } from '../../models/mcd';


// @Injectable({
//   providedIn: 'root'
// })
// export class AuthService {
//   private currentUserSubject: BehaviorSubject<any>;
//   public currentUser: Observable<any>;

//   constructor(
//     private http: HttpClient,
//     private cookies: CookieService) {
//     this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')));
//     this.currentUser = this.currentUserSubject.asObservable();
//   }

//   public get currentUserValue() {
//     return this.currentUserSubject.value;
//   }

//   // Get user's token
//   getToken(): string {
//     return localStorage.getItem('token');
//   }

//   // Login user
//   // login(email: string, password: string): Observable<any> {
//   //   return this.http.post<any>(environment.apiUrl + '/login', { email, password }).pipe(
//   //     tap(response => {
//   //       this.setCurrentUser(response),
//   //       this.redirectLoggedUser(response.user.role_type, response.token)
//   //     }),
//   //     catchError(error => this.handleError(error))
//   //   );
//   // }
  
//   // Redirect logged user to the corresponding plateforme
//   redirectLoggedUser(role_type: string, token: string) {
//     if (role_type == 'Service Station') {
//       window.location.href = environment.light_oil;
//     } else if (role_type == 'Admin' || 'Moderator') {
//       window.location.href = environment.admin_view + '?' + 'uli=' + token;
//     } else if (role_type == 'Super Admin') {
//       window.location.href = environment.super_admin_view;
//     }
//   }

//   // Get authenticated user
//   getUser(): Observable<User> {
//     const headers = new HttpHeaders({Authorization: `Bearer ${this.getToken()}`});
//     return this.http.get<User>(environment.apiUrl + '/user', { headers: headers })
//       .pipe(
//         catchError(error => this.handleError(error))
//       );
//   }

//   // Logout user
//   logout(): Observable<any> {
//     const headers = new HttpHeaders({Authorization: `Bearer ${this.getToken()}`});
//     return this.http.post<any>(environment.apiUrl + '/logout', { headers: headers })
//       .pipe(
//         tap(() => this.clearCurrentUser()),
//         catchError(error => this.handleError(error))
//       );
//   }

//   // Add athenticated user's data to the Local Storage
//   private setCurrentUser(response: any): void {
//     localStorage.setItem('user', JSON.stringify(response.user));
//     localStorage.setItem('token', response.token);
//     localStorage.setItem('token_type', response.token_type);
//     localStorage.setItem('token_expires_at', response.token_expires_at);
//     this.currentUserSubject.next(response.user);
//   }

//   // Delete athenticated user's data to the Local Storage
//   private clearCurrentUser(): void {
//     localStorage.removeItem('user');
//     localStorage.removeItem('token');
//     localStorage.removeItem('token_type');
//     localStorage.removeItem('token_expires_at');
//     this.currentUserSubject.next(null);
//   }

//   private handleError(error: any): Observable<never> {
//     // Manage authentication errors here
//     console.error('Erreur d\'authentification :', error);
//     return throwError(error);
//   }

//   login(user: userSignIn, callBackSuccess, callBackError): Promise<any> {
//     return this.http.post(environment.oauth, user).toPromise().then((data: any) => {
//       if (data != null) {
//         this.obtainInfos(data.access_token);
//         callBackSuccess(true);
//       } else {
//         callBackSuccess(false);
//       }
//     }).catch(error => {
//       callBackError(error);
//     });
//   }

//   obtainInfos(token): Promise<any> {
//     let headers = new HttpHeaders({
//       'Content-type': 'application/x-www-form-urlencoded; charset=utf-8', 'Authorization': 'Bearer '+token
//     });
//     let options = {
//       headers: headers
//     };
//     return this.http.get(environment.apiUrl + 'log-user', options).toPromise().then((data: any) => {
//       console.log("data: ", data, "token: ", token);
//       //localStorage.setItem(this.JWT_TOKEN, JSON.stringify(data));
//       this.cookies.set('User', JSON.stringify(data))
//       this.cookies.set('token', token);
//       setTimeout(() => this.redirectLoggedUser(data.type_role, data.log.token),2000)
//     });
//   }

// }


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of} from 'rxjs';
import { tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { userSignIn } from '../../models/mcd';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {


  link: string = "/redirect?url=" + environment.redirect_uri_path;


  constructor(
    private http: HttpClient,
    private router: Router,
    private cookies: CookieService,
    ) {}

    getServiceStation(){
      let headers = new HttpHeaders({
        'Content-type': 'application/json; charset=utf-8'
      });
      let options = {
        headers: headers
      };
      return this.http.get(environment.apiUrl+'lightoil/get-default-config/get', options);
    }

  login(user: userSignIn, callBackSuccess, callBackError): Promise<any> {
    return this.http.post(environment.oauth, user).toPromise().then((data: any) => {
      if (data != null) {
        this.obtainInfos(data.access_token);
        callBackSuccess(true);
      } else {
        callBackSuccess(false);
      }
    }).catch(error => {
      callBackError(error);
    });
  }

  obtainInfos(token): Promise<any> {
    let headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8', 'Authorization': 'Bearer '+token
    });
    let options = {
      headers: headers
    };
    return this.http.get(environment.apiUrl + 'log-user', options).toPromise().then((data: any) => {
        console.log("data: ",data, " token: ",token);
        //localStorage.setItem(this.JWT_TOKEN, JSON.stringify(data));
        this.cookies.set('User', JSON.stringify(data))
        this.cookies.set('token', token);
        setTimeout(()=> this.redirectSignedInUser(data.type_role[0], data.log.token),4000)
    });
  }

  redirectSignedInUser(user, log_id) {
    // if(user.code == 'DIRECTORGENERAL') {
    //   window.location.href = environment.admin_view + '?' + 'uli=' + log_id;
    // } else {
    //   window.location.href = environment.light_oil;

    // }
    if(user.name == 'SERVICESTATION') {
      window.location.href = environment.light_oil;
    }else if(user.name == 'SUPER_ADMIN') {
      window.location.href = environment.super_admin;
    }else {
      window.location.href = environment.admin_view + '?' + 'uli=' + log_id;
    }

    /**
     * function receive data from pump counter
     * 
     * @param Request $newData
     * @return Json [status, data]
     * 
     * route : // streaming/collector/receive-from-pump-counter
     */

    /**
     * function receive data from flow meter
     * 
     * @param Request $newData
     * @return Json [status, data]
     * 
     * route : // streaming/collector/receive-from-flow-meter
     */


  }

}
