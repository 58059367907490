import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'volumeAt15'
})

export class VolumeAt15Pipe implements PipeTransform{
  transform(vol: number): string {
    if (vol !== undefined && vol !== null) {
      return  String(vol * 0.9837);
    } else {
      return  '';
    }
  }
}
