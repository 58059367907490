import { trigger, transition, style, animate, query, group, keyframes, state, animation, useAnimation } from '@angular/animations';

export const Fade = trigger('fade', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('2500ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [animate('1500ms', style({ opacity: 0 }))]),
]);

export const RouteFadeAnimation = [
         trigger('routeFadeAnimation', [
      transition('* => *', [
        style({ position: 'relative' }),
          query(
            ':enter, :leave',
            [
              style({
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                opacity: 0,
              }),
            ],
            { optional: true }
          ),
        query(':enter', [style({ opacity: 0 })], {
          optional: true,
        }),
        group([
          query(
            ':leave',
            [style({ opacity: 1 }), animate('500ms', style({ opacity: 0 }))],
            { optional: true }
          ),
          query(
            ':enter',
            [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))],
            { optional: true }
          ),
        ]),
      ]),
    ]),
];

export const EnterExitLeft = [

    trigger('enterExitLeft', [
    transition(':enter', [
      style({ opacity: 0, transform: 'translateX(-200px)' }),
      animate(
        '2500ms ease-in',
        style({ opacity: 1, transform: 'translateX(0)' })
      ),
    ]),
    transition(':leave', [
      animate(
        '1500ms ease-in',
        style({ opacity: 0, transform: 'translateX(-200px)' })
      ),
    ]),
  ]),
];

export const EnterExitRight = [
  trigger('enterExitRight', [
    transition(':enter', [
      style({ opacity: 0, transform: 'translateX(200px)' }),
      animate(
        '2500ms ease-in',
        style({ opacity: 1, transform: 'translateX(0)' })
      ),
    ]),
    transition(':leave', [
      animate(
        '1500ms ease-in',
        style({ opacity: 0, transform: 'translateX(200px)' })
      ),
    ]),
  ]),
];

export const FadeSlide = [
    trigger('fadeSlide', [
    transition(':enter', [
      style({ opacity: 0, transform: 'scale(0.5) translateY(50px)' }),
      animate(
        '1500ms',
        keyframes([
          style({ opacity: 1, offset: 0.3 }),
          style({ transform: 'translateY(0)', offset: 0.6 }),
          style({ transform: 'scale(1)', offset: 1 }),
        ])
      ),
    ]),
  ]),
];

export const FadeList = [
  trigger('fadeList', [
      state(
        'fadeInFlash',
        style({
          opacity: '2'
        })
      ),
      transition('void => *', [
        style({ opacity: '0', transform: 'translateY(20px)' }),
        animate('500ms')
      ])
    ]),
];


const reusable = animation([
	style({
		opacity: '{{opacity}}',
		transform: 'scale({{scale}}) translate3d({{x}}, {{y}}, {{z}})'
	}),
	animate('{{duration}} {{delay}} cubic-bezier(0.0, 0.0, 0.2, 1)', style('*'))
], {
		params: {
			duration: '200ms',
			delay: '0ms',
			opacity: '0',
			scale: '1',
			x: '0',
			y: '0',
			z: '0'
		}
	});

export const SharedAnimations = [
	trigger('animate', [transition('void => *', [useAnimation(reusable)])]),

trigger('fadeInOut', [
	state('0', style({
			opacity: 0,
			display  : 'none'
	})),
	state('1', style({
			opacity: 1,
			display  : 'block'
	})),
	transition('0 => 1', animate('300ms')),
	transition('1 => 0', animate('300ms'))
]),
];

