<main class="text-center user-select-none" style="background-size: cover; background-color: #000" id="mainParent">

        <form class="form-signin col-12 bg-white jumbotron user-select-none" [formGroup]="loginForm" (submit)="login()">
          <!-- <h2 class=" mb-4 font-weight-bold ">LightOil</h2> -->
          <div class="tw-flex tw-justify-center tw-items-center">
            <img src="../../../../assets/images/lightoil.svg" style="width: 60%"/>
          </div>
          <span class="text-center pt-2 pb-3">Authentification</span>
          <br><br>

          <div class="col-sm-12 my-1">
              <label class="sr-only" for="inlineFormInputGroupUsername">Email</label>
              <div class="input-group" [ngClass]="{ 'border-info': loginForm.controls.email.valid, 'border-danger': loginForm.controls.email.errors }">
                  <div class="input-group-text">
                    <img src="https://img.icons8.com/material/24/000000/email.png"/>
                  </div>
                <input type="text" class="form-control" formControlName="email" id="inlineFormInputGroupUsername" placeholder="Email">
              </div>
            </div>

            <div class="col-sm-12 my-1">
                <label class="sr-only" for="inlineFormInputGroupUsername">Password</label>
                <div class="input-group" [ngClass]="{ 'border-info': loginForm.controls.password.valid, 'border-danger': loginForm.controls.password.errors }" >
                    <div class="input-group-text" style="height: 47px">
                      <img src="https://img.icons8.com/material/24/000000/unlock-2.png"/>
                    </div>
                  <input type="password" class="form-control" formControlName="password" id="inlineFormInputGroupUsername" placeholder="Mot de passe">
                </div>
              </div>
          <button class="btn btn-outline-info mx-auto mt-3 text-capitalize" [disabled]="loginForm.invalid" type="submit">
            <span [ngClass]="[click ? 'spinner-border' : '', click ? 'spinner-border-sm' : '']" role="status"
              aria-hidden="true">
            </span>
            {{message}}
          </button>

        </form>
</main>


