import { Injectable } from '@angular/core';
// import { config } from 'src/app/config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BackgroundService {

  constructor() {}

  animateBackground(id: any, index: any) {
    const images = ['intro.jpeg', 'intro1.jpeg', 'intro2.jpeg'];
    if (index > (images.length - 1)) {
      this.animateBackground(id, 0);
    } else {
      setTimeout(() => {
        document.getElementById(id).style.transition = `4s`;
        document.getElementById(id).style.backgroundImage = `url(././assets/images/${images[index]})`;
        this.animateBackground(id, index + 1);
      }, 7000);
    }
  }

}
