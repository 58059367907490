import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return;
  }

  private addToken(request: HttpRequest<any>, tokens: any) {
    return request.clone({
      withCredentials: true,
      setHeaders: tokens != null ? {
        Authorization: `Bearer`,
        'X-token': tokens.token,
        Role: tokens.role
      } : {
        Authorization: `Bearer`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return
  }
}
