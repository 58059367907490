import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  SeoService,
  StateService,
} from './utils';

import { DateWithHyphenPipe } from './pipe/date-with-hyphen.pipe';
import {NavigationServiceService} from './services/navigation-service.service';

export const NB_CORE_PROVIDERS = [
  AnalyticsService,
  LayoutService,
  SeoService,
  StateService,
  NavigationServiceService
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    DateWithHyphenPipe,
  ],
  declarations: [DateWithHyphenPipe],
  providers:    [DateWithHyphenPipe]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
