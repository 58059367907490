<div class="header-container">
  <div class="logo-container">
    <a href="javascript:void(0);" class="sidebar-toggle" style="border-right: 2px solid #3E3E3E">
      <button nbButton status="info" outline>
        Menu
      </button>
    </a>
    <span><img src="assets/images/lightoil.svg" width="100px" height="120px" style="margin-left: 4%" /></span>
  </div>
</div>

<div class="header-container small-list">
  <nb-actions size="medium">
    <nb-action class="control-item" nbPopover="Notifications Alertes" badgeDot badgePosition="top right"
      badgeStatus="danger" nbPopoverPlacement="bottom" icon="bell-outline" *ngIf="!datas.length"
      nbPopoverTrigger="hint">
    </nb-action>
  </nb-actions>
</div>
