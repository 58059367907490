import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateWithHyphen'
})
export class DateWithHyphenPipe implements PipeTransform {

  transform(val: string): string {
    if (val !== undefined && val !== null) {
        let d = new Date(val),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) {
          month = '0' + month;
        }
        if (day.length < 2) {
          day = '0' + day;
        }
        return [year, month, day].join('-');
    } else {
      return '';
    }
  }

}
