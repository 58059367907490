import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { Subject } from 'rxjs';
import {Router} from '@angular/router';
import {rejects} from 'assert';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  user: any;
  tanks = [];
  selectedTank;
  alert;
  currentTheme = 'default';
  public datas: any = [];
  tankId;
  countNotification: any = null;


  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private cdRef: ChangeDetectorRef,
              private router: Router,
              ) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
